import React, {useState, useEffect} from "react";
import ajax from '../utils/ajax'
import {NavLink}  from "react-router-dom";
import {SvgIcon, Badge, BottomNavigation, BottomNavigationAction, Chip, FormControl, FormControlLabel, FormGroup, FormLabel, RadioGroup, Radio, Checkbox, Paper, Divider, TableCell, Button, Grid, Card, Typography, CardContent, CardActions, CardHeader, CardMedia, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction} from '@material-ui/core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Modal} from 'react-bootstrap';
import {AccomodationForm} from '../components/AccomodationForm/AccomodationForm'
import {useGlobalData} from '../hooks/useGlobalData'
import {useWindowSize} from '../hooks/useWindowSize'
import './Home.scss'
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EventNoteIcon from '@material-ui/icons/EventNote';
import './Article.scss'

const saveBlob = (function () {
  var a = document.createElement("a")
  document.body.appendChild(a)
  a.style = "display: none"
  return function (blob, fileName) {
    var url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }
}())

const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
const mobileSize = 720

const twoDigitString = (n) => {
  if (n < 10) return ('0' + n.toString())
  return n.toString()
}
const shortDateString = (date, noDay) => {
  return (noDay ? '' : days[date.getDay()]) + ' ' + twoDigitString(date.getDate()) + '/' + twoDigitString(date.getMonth() + 1) + '/' + date.getFullYear()
}
const longDateString = (date) => {
  return days[date.getDay()] + ' ' + (date.getDate()) + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
}
const timeString = (date) => {
  return twoDigitString(date.getHours()) + ':' + twoDigitString(date.getMinutes())
}

export const Home = () => {
  const [articles, setArticles] = useState(null)
  const [orders, setOrders] = useState(null)
  const [surveys, setSurveys] = useState(null)
  const [shops, setShops] = useState(null)
  const [shopsForOrder, setShopsForOrder] = useState(null)
  const [deals, setDeals] = useState(null)
  const [meetings, setMeetings] = useState(null)
  const [commitees, setCommitees] = useState(null)
  const [commiteeArray, setCommiteeArray] = useState(null)
  const [currentMeeting, setCurrentMeeting] = useState(null)
  const [currentRegistration, setCurrentRegistration] = useState(null)
  const [tab, setTab] = useState('articles')
  const size = useWindowSize()

  const globalData = useGlobalData()
  const user = globalData.user

  const fetch = () => {
    if (!user) return

    ajax.get('/api/articles/public').then(r => {
      setArticles(r)
    })
    ajax.get('/api/deals').then(r => {
      setDeals(r.filter(deal => deal.status === 'published'))
    })
    ajax.get('/api/meetings/me').then(r => {
      r = r.filter(m => new Date(m.date).getTime() > Date.now())
      r.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      setMeetings(r)
    })
    ajax.get('/api/commitees').then(r => {
      let commitees = {}
      for (let commitee of r) {
        commitees[commitee._id] = commitee
      }
      setCommitees(commitees)
      r.sort((a, b) => a.name.localeCompare(b.name))
      setCommiteeArray(r)
    })
    ajax.get('/api/surveys/public').then(r => {
      setSurveys(r)
    })
    ajax.get('/api/orders/public').then(r => {
      setOrders(r)
    })
    ajax.get('/api/user/shops').then(res => {
      setShops(res)
      setShopsForOrder(res.filter(s => !s.noOrders))
    })
  }
  useEffect(fetch, [user])

  const renderArticles = () => {
    return articles.map(article => (
      <Grid item key={article._id} className='ArticleCard'>
        <Card raised style={{display: 'flex', flexDirection: 'column'}}>
          <CardHeader
            avatar={<Avatar alt={article.author.firstName + ' ' + article.author.lastName.toUpperCase()} src={"/api/media/thumbnail/" + article.author.picture} />}
            title={article.author.firstName + ' ' + article.author.lastName.toUpperCase()}
            subheader={shortDateString(new Date(article.date), true)}
            action={
              !article.hasRead && new Date(article.date).getTime() >= new Date(2021, 1, 24).getTime() && <Chip size='small' label="Nouveau" color="secondary" />
            }
          />
          {
            article.picture && (
              <NavLink to={"/article/" + article._id}>
                <CardMedia
                  style={{height: 0, paddingTop: '56.25%', backgroundSize: 'contain'}}
                  image={"/api/media/" + article.picture}
                  title={article.title}
                />
              </NavLink>
            )
          }
          <CardContent style={{flexGrow: 1, flexShrink: 1, minHeight: 0, display: 'flex', flexDirection: 'column'}}>
            <Typography gutterBottom variant="h5" component="h2">
              {article.title}
            </Typography>
            <div style={{position: 'relative', overflow: 'hidden', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
              <div dangerouslySetInnerHTML={{__html: article.content}} className='ArticleContent' />
              <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: '30px', background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)'}} />
            </div>
          </CardContent>
          {article.content && (
            <CardActions>
              <NavLink to={"/article/" + article._id}>
                <Button variant="contained" color="primary">
                  Lire l'article
                </Button>
              </NavLink>
            </CardActions>
          )}
        </Card>
      </Grid>

    ))
  }

  const renderDeals = () => {
    return deals.map(article => (
      <Grid item key={article._id} style={{width: '100%'}}>
        <Card raised style={{height: '300px', display: 'flex', flexDirection: 'column'}}>
          <CardHeader
            avatar={article.author && <Avatar alt={article.author.firstName + ' ' + article.author.lastName.toUpperCase()} src={"/api/media/thumbnail/" + article.author.picture} />}
            title={article.author && (article.author.firstName + ' ' + article.author.lastName.toUpperCase())}
            subheader={shortDateString(new Date(article.date))}
            action={
              !article.hasRead && new Date(article.date).getTime() >= new Date(2021, 2, 24).getTime() && <Chip size='small' label="Nouveau" color="secondary" />
            }
          />
          {
            article.picture && (
              <NavLink to={"/bonne-affaire/" + article._id}>
                <CardMedia
                  style={{height: 0, paddingTop: '56.25%'}}
                  image={"/api/media/" + article.picture}
                  title={article.title}
                />
              </NavLink>
            )
          }
          <CardContent style={{flexGrow: 1, flexShrink: 1, minHeight: 0, display: 'flex', flexDirection: 'column'}}>
            <Typography gutterBottom variant="h5" component="h2" style={{flexGrow: 0, flexShrink: 0}}>
              {article.title}
            </Typography>
            <div style={{position: 'relative', overflow: 'hidden', flexGrow: 1, flexShrink: 1, minHeight: 0}}>
              <div dangerouslySetInnerHTML={{__html: article.content}} className='ArticleContent' />
              <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: '30px', background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)'}} />
            </div>
          </CardContent>
          <CardActions>
            <NavLink to={"/bonne-affaire/" + article._id}>
              <Button variant="contained" color="primary">
                Lire l'annonce
              </Button>
            </NavLink>
          </CardActions>
        </Card>
      </Grid>

    ))
  }

  const showMeeting = (meeting) => {
    setCurrentMeeting(meeting)

    let registered = meeting.registered && meeting.registered.findIndex(reg => reg.user === user._id) >= 0
    if (!registered) {
      let reg = {options: {}}
      for (let dateStr in meeting.options) {
        if (meeting.options[dateStr].selected) {
          reg.options[dateStr] = {selected: false, matin: false, midi: false, soir: false, nuitee: false, typeChambre: 'single'}
        }
      }
      setCurrentRegistration(reg)
    } else {
      setCurrentRegistration(meeting.registered[meeting.registered.findIndex(reg => reg.user === user._id)])
    }
  }

  const renderMeetings = () => {

    const pdfName = (meeting, type) => {
      let typeName = ''
      if (type === 'items') typeName = 'Ordre_du_jour'
      else if (type === 'registrations') typeName = 'Liste_des_inscrits'
      if (type === 'report') typeName = 'Compte_rendu'

      return commitees[meeting.commitee].name + '-' + shortDateString(new Date(meeting.date)) + '-' + typeName + '.pdf'
    }

    const pdfDocument = (meeting, type) => {
      if (!meeting || !meeting.files) return undefined
      let files = [...meeting.files]
      return files.reverse().find(d => d.type === type)
    }

    const downloadDocument = (meeting, type) => {
      let file = pdfDocument(meeting, type)
      if (!file) return

      ajax.get('/api/document/' + file.media, {responseType: 'arraybuffer'}).then(res => {
        const blob = new Blob([res], { type: 'application/pdf' })
        saveBlob(blob, pdfName(meeting, type))
      })
    }

    const downloadPDF = (meeting) => {
      if (meeting.hasPdfReport) {
        downloadDocument(meeting, 'items')
        return
      }

      ajax.get('/api/meeting/' + meeting._id + '/pdf/items', {responseType: 'arraybuffer'}).then(res => {
        const blob = new Blob([res], { type: 'application/pdf' })
        saveBlob(blob, commitees[meeting.commitee].name + '-' + shortDateString(new Date(meeting.date)) + '.pdf')
      })
    }

    return (
      <div style={{paddingBottom: '0px', width: '100%', display: "flex", flexDirection: 'column'}}>
        {size.width > mobileSize && <Typography variant='h6' style={{paddingLeft: '16px', paddingTop: '10px', flexGrow: 0, flexShrink: 0}}>Prochaines réunions</Typography>}
        {
          (meetings.length === 0) && (
            <Typography style={{marginTop: '20px', fontStyle: 'italic'}}>Aucune réunion planifiée dans vos commissions.</Typography>
          )
        }
        <List style={{flexGrow: 1, flexShrink: 1, minHeight: 0, overflowY: 'auto', paddingTop: 0, paddingBottom: 0}}>
          {
            meetings.map((meeting, i) => {
              let date = new Date(meeting.date)
              let downloadProgram = downloadPDF.bind(null, meeting)
              let register = showMeeting.bind(null, meeting)
              let registered = meeting.registered && meeting.registered.findIndex(reg => reg.user === user._id) >= 0
              let missing = true
              if (registered) {
                let reg = meeting.registered.find(reg => reg.user === user._id)
                for (let key in reg.options) {
                  if (reg.options[key].selected) {
                    missing = false
                    break
                  }
                }
              }
              let canRegister = new Date(meeting.registrationDeadline).getTime() > new Date().getTime()
              return (
                <>
                  <ListItem key={meeting._id}>
                    <ListItemText primary={commitees && commitees[meeting.commitee._id].name} secondary={
                        <div>
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', lineHeight: 1, marginTop: '5px'}}>
                            <FontAwesomeIcon icon='clock' style={{marginRight: '5px'}} />
                            {shortDateString(date) + ', ' + timeString(date)}
                          </div>
                          {
                            meeting.place && (
                              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', lineHeight: 1, marginTop: '5px'}}>
                                <FontAwesomeIcon icon='map-marker' style={{marginRight: '8.5px'}} />
                                {meeting.place || ''}
                              </div>
                            )
                          }
                        </div>
                      } />
                    <ListItemSecondaryAction>
                      <Grid container direction='column' spacing={1} style={{alignItems: 'flex-end'}}>

                        {
                          (!meeting.hasPdfReport && meeting.items !== "" || meeting.hasPdfReport && meeting.files.find(f => f.type === 'items')) && (
                            <Grid item>
                              <Button variant="outlined" color='primary' size="small" onClick={downloadProgram}>
                                <FontAwesomeIcon icon='download' style={{marginRight: '10px'}} />
                                Ordre du jour
                              </Button>
                            </Grid>
                          )
                        }
                        {
                          canRegister && registered && (
                            <Grid item>
                              <Button variant="outlined" size="small" onClick={register}>
                                <FontAwesomeIcon icon={missing ? 'user-times' : 'user-check'} style={{marginRight: '10px'}} />
                                {missing ? "Absent - Modifier" : "Inscrit - Modifier"}
                              </Button>
                            </Grid>
                          )
                        }
                        {
                          canRegister && !registered && (
                            <Grid item>
                              <Button variant="outlined" color='primary' size="small" onClick={register}>
                                <FontAwesomeIcon icon='user-edit' style={{marginRight: '10px'}} />
                                Inscription
                              </Button>
                            </Grid>
                          )
                        }
                        {
                          !canRegister && registered && (
                            <Grid item>
                              <Button variant="outlined" size="small">
                                <FontAwesomeIcon icon={missing ? 'user-times' : 'user-check'} style={{marginRight: '10px'}} />
                                {missing ? "Absent" : "Inscrit"}
                              </Button>
                            </Grid>
                          )
                        }
                      </Grid>

                    </ListItemSecondaryAction>
                  </ListItem>
                  {i < meetings.length -1 && <Divider key={meeting._id + '_key'} style={{marginLeft: '8px', marginRight: '8px'}}/>}
                </>
              )
            })
          }
        </List>
      </div>
    )
  }

  const renderSurveys = () => {
    return (
      <div style={{paddingBottom: '0px', width: '100%', display: "flex", flexDirection: 'column'}}>
        {size.width > mobileSize && <Typography variant='h6' style={{paddingLeft: '16px', paddingTop: '10px', flexGrow: 0, flexShrink: 0}}>Sondages en cours</Typography>}
        {
          (surveys.length === 0) && (
            <Typography style={{marginTop: '20px', fontStyle: 'italic'}}>Aucun sondage en cours.</Typography>
          )
        }
        <List style={{flexGrow: 1, flexShrink: 1, minHeight: 0, overflowY: 'auto', paddingTop: 0, paddingBottom: 0}}>
          {
            surveys && shops && surveys.map((survey, i) => {
              let hasAnswered = false
              for (let shop of shops) {
                if (survey.answers[shop._id]) {
                  hasAnswered = true
                  break
                }
              }

              return (
                <>
                  <ListItem key={survey._id}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                      <ListItemText primary={survey.title} style={{flexGrow: 1, flexShrink: 1, minWidth: 0, lineHeight: 1.2}} secondary={
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', lineHeight: 1, marginTop: '5px'}}>
                          <FontAwesomeIcon icon='clock' style={{marginRight: '5px'}} />
                          {survey.hasEndDate ? shortDateString(new Date(survey.endDate), true) : 'Aucune date limite'}
                        </div>
                        } />
                      <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '10px'}}>
                        <Grid container direction='column' spacing={1} style={{alignItems: 'flex-end'}}>
                          <Grid item>
                            {
                              !hasAnswered
                              ? (
                                <a href={"/sondage/" + survey._id}>
                                  <Button variant="outlined" color='primary' size="small">
                                    <FontAwesomeIcon icon='user-edit' style={{marginRight: '15px'}} />
                                    Répondre au sondage
                                  </Button>
                                </a>

                              )
                              : (
                                <a href={"/sondage/" + survey._id}>
                                  <Button variant="outlined" color='secondary' size="small">
                                    <FontAwesomeIcon icon='user-check' style={{marginRight: '15px'}} />
                                    Modifier mes réponses
                                  </Button>
                                </a>
                              )
                            }
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                  </ListItem>
                  {i < surveys.length -1 && <Divider key={survey._id + '_key'} style={{marginLeft: '8px', marginRight: '8px'}}/>}
                </>
              )
            })
          }
        </List>
      </div>
    )
  }

  const renderOrders = () => {
    return (
      <div style={{paddingBottom: '0px', width: '100%', display: "flex", flexDirection: 'column'}}>
        {size.width > mobileSize && <Typography variant='h6' style={{paddingLeft: '16px', paddingTop: '10px', flexGrow: 0, flexShrink: 0}}>Commandes en cours</Typography>}
        {
          (orders.length === 0) && (
            <Typography style={{marginTop: '20px', fontStyle: 'italic'}}>Aucune commande en cours.</Typography>
          )
        }
        <List style={{flexGrow: 1, flexShrink: 1, minHeight: 0, overflowY: 'auto', paddingTop: 0, paddingBottom: 0}}>
          {
            orders && shopsForOrder && orders.map((order, i) => {
              let hasAnswered = false
              for (let shop of shopsForOrder) {
                if (order.answers[shop._id]) {
                  hasAnswered = true
                  break
                }
              }

              return (
                <>
                  <ListItem key={order._id}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                      <ListItemText primary={order.title} style={{flexGrow: 1, flexShrink: 1, minWidth: 0, lineHeight: 1.2}} secondary={
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', lineHeight: 1, marginTop: '5px'}}>
                          <FontAwesomeIcon icon='clock' style={{marginRight: '5px'}} />
                          {order.hasEndDate ? shortDateString(new Date(order.endDate), true) : 'Aucune date limite'}
                        </div>
                        } />
                      <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '10px'}}>
                        <Grid container direction='column' spacing={1} style={{alignItems: 'flex-end'}}>
                          <Grid item>
                            {
                              !hasAnswered
                              ? (
                                <a href={"/commande/" + order._id}>
                                  <Button variant="outlined" color='primary' size="small">
                                    <FontAwesomeIcon icon='user-edit' style={{marginRight: '15px'}} />
                                    Passer ma commande
                                  </Button>
                                </a>

                              )
                              : (
                                <a href={"/commande/" + order._id}>
                                  <Button variant="outlined" color='secondary' size="small">
                                    <FontAwesomeIcon icon='user-check' style={{marginRight: '15px'}} />
                                    Modifier ma commande
                                  </Button>
                                </a>
                              )
                            }
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                  </ListItem>
                  {i < orders.length -1 && <Divider key={order._id + '_key'} style={{marginLeft: '8px', marginRight: '8px'}}/>}
                </>
              )
            })
          }
        </List>
      </div>
    )
  }

  const saveRegistration = (missing) => {
    let body = {...currentRegistration}
    console.log(missing)
    if (missing) {
      for (let key in currentRegistration.options) {
        currentRegistration.options[key] = {selected: false, matin: false, midi: false, soir: false, nuitee: false, typeChambre: 'single'}
      }
    }
    ajax.post('/api/meeting/' + currentMeeting._id + '/register', body).then(() => {
      setCurrentMeeting(null)
      ajax.get('/api/meetings/me').then(r => {
        r = r.filter(m => new Date(m.date).getTime() > Date.now())
        r.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        setMeetings(r)
      })
    })
  }

  let date, yesterday, tomorrow
  if (currentMeeting) {
    date = new Date(currentMeeting.date)
    yesterday = new Date(date)
    yesterday.setDate(date.getDate() - 1)
    tomorrow = new Date(date)
    tomorrow.setDate(date.getDate() + 1)
  }

  return (
    <>
      <Grid className='HomeGrid' container direction='row' justify='space-between'>
        {
          (size.width > mobileSize || tab === 'articles') && (
            <Grid item container direction='row' spacing={2} style={{flexGrow: 1, flexShrink: 1, minWidth: "300px", width: 'unset', paddingBottom: (size.width <= mobileSize ? '56px' : undefined)}}>
              {articles && renderArticles()}
            </Grid>
          )
        }

        <Grid item style={{flexGrow: 0, flexShrink: 0, width: (size.width > mobileSize ? '400px' : '100%'), maxWidth: '100%', marginLeft: (size.width > mobileSize ? '10px' : undefined), paddingBottom: (size.width <= mobileSize ? '56px' : undefined)}}>
        {
            (user && user.role === 'adherent') && (size.width > mobileSize || tab === 'surveys') && (
              <Paper variant="outlined" square style={{width: '100%', maxHeight: (size.width > mobileSize ? '600px' : undefined), display: 'flex', marginBottom: '10px'}}>
                {surveys && surveys.length > 0 && user && renderSurveys()}
              </Paper>
            )
          }
          {
            (user && user.role === 'adherent') && (size.width > mobileSize || tab === 'orders') && (
              <Paper variant="outlined" square style={{width: '100%', maxHeight: (size.width > mobileSize ? '600px' : undefined), display: 'flex', marginBottom: '10px'}}>
                {orders && orders.length > 0 && user && renderOrders()}
              </Paper>
            )
          }
          {
            (size.width > mobileSize || tab === 'meetings') && (
              <Paper variant="outlined" square style={{width: '100%', maxHeight: (size.width > mobileSize ? '600px' : undefined), display: 'flex'}}>
                {meetings && user && renderMeetings()}
              </Paper>
            )
          }

          {
            size.width > mobileSize
            ? (
              <Paper style={{marginTop: '10px', padding: '15px', maxWidth: '100%'}} variant='outlined' square>
                <Typography style={{marginBottom: '10px'}} variant='h6'>Coin des bonnes affaires</Typography>
                <Grid item container direction='row' spacing={2} style={{flexGrow: 1, flexShrink: 1, minWidth: 0, width: 'unset'}}>
                  {deals && renderDeals()}
                </Grid>
              </Paper>
            )
            : (
              tab === 'deals' && (
                <Grid item container direction='row' spacing={2} style={{flexGrow: 1, flexShrink: 1, minWidth: 0, width: 'unset', paddingBottom: '56px'}}>
                  {deals && renderDeals()}
                </Grid>
              )
            )
          }
        </Grid>

        <Modal size="xl" scrollable show={currentMeeting !== null}>
          <Modal.Header>
            <Modal.Title>
              {currentMeeting && commitees[currentMeeting.commitee._id].name + ' - Réunion du ' + longDateString(new Date(currentMeeting.date)) + ", " + timeString(new Date(currentMeeting.date)) + (currentMeeting.place ? (' - ' + currentMeeting.place) : '')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              currentMeeting && currentRegistration && (
                <Grid item container direction='column' spacing={1}>
                  <Grid item><Typography variant='h6'>Hébergement / Restauration proposés</Typography></Grid>
                  <Grid item><Typography>Veuillez cocher vos jours de présence ainsi que les prestations d'hébergement et de restauration souhaitées.</Typography></Grid>
                  <Grid item>
                    <AccomodationForm options={currentRegistration.options} availableOptions={currentMeeting.options} setOptions={(options) => setCurrentRegistration(prevState => ({...prevState, options: options}))} />
                  </Grid>
                  <Grid item style={{marginTop: '30px'}}>
                    <div>Si vous n'êtes pas en mesure de participer à cette réunion, veuillez nous le signifier en cliquant sur le bouton ci-dessous :</div>
                    <Button variant="contained" color="error" onClick={() => {saveRegistration(true)}}>
                      Je serai absent
                    </Button>
                  </Grid>
                </Grid>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setCurrentMeeting(null) }}>Annuler</Button>
            <Button variant="contained" color="primary" onClick={saveRegistration.bind(null, false)}>
              {
                currentMeeting && currentMeeting.registered && currentMeeting.registered.findIndex(reg => reg.user === user._id) >= 0
                ? "Modifier mon inscription"
                : "Valider mon inscription"
              }
            </Button>
          </Modal.Footer>
        </Modal>
      </Grid>

      <BottomNavigation value={tab} onChange={(e, newValue) => { setTab(newValue) }} className='BottomBar'>
        <BottomNavigationAction label='Articles' value='articles' showLabel icon={
          <SvgIcon>
            <g id="Stockholm-icons-/-Home-/-Book-open" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect id="bound" x="0" y="0" width="24" height="24"></rect>
              <path d="M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z" id="Combined-Shape" fill="#000000"></path>
              <path d="M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z" id="Path-41-Copy" fill="#000000" opacity="0.3"></path>
            </g>
          </SvgIcon>
          } />
        <BottomNavigationAction label='Réunions' value='meetings' showLabel icon={
          <SvgIcon>
            <g id="Stockholm-icons-/-Home-/-Clock" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect id="bound" x="0" y="0" width="24" height="24"></rect>
              <path d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z" id="Mask" fill="#000000" opacity="0.3"></path>
              <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" id="Path-107" fill="#000000"></path>
            </g>
          </SvgIcon>
          } />
        <BottomNavigationAction label='Bonnes affaires' value='deals' showLabel icon={
            <SvgIcon color="secondary">
              <g id="Stockholm-icons-/-General-/-Like" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                <path d="M9,10 L9,19 L10.1525987,19.3841996 C11.3761964,19.7920655 12.6575468,20 13.9473319,20 L17.5405883,20 C18.9706314,20 20.2018758,18.990621 20.4823303,17.5883484 L21.231529,13.8423552 C21.5564648,12.217676 20.5028146,10.6372006 18.8781353,10.3122648 C18.6189212,10.260422 18.353992,10.2430672 18.0902299,10.2606513 L14.5,10.5 L14.8641964,6.49383981 C14.9326895,5.74041495 14.3774427,5.07411874 13.6240179,5.00562558 C13.5827848,5.00187712 13.5414031,5 13.5,5 L13.5,5 C12.5694044,5 11.7070439,5.48826024 11.2282564,6.28623939 L9,10 Z" id="Path-42" fill="#000000"></path>
                <rect id="Rectangle-153" fill="#000000" opacity="0.3" x="2" y="9" width="5" height="11" rx="1"></rect>
              </g>
            </SvgIcon>
          } />
        {
          user && user.role !== 'marketing' && (
            <BottomNavigationAction label='Commandes' value='orders' showLabel icon={
              <SvgIcon color="secondary">
                <g id="Stockholm-icons-/-Communication-/-Clipboard-list" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" id="Combined-Shape" fill="#000000"></path>
                    <rect id="Rectangle-152" fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"></rect>
                    <rect id="Rectangle-152-Copy-2" fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"></rect>
                    <rect id="Rectangle-152-Copy-3" fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"></rect>
                    <rect id="Rectangle-152-Copy" fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"></rect>
                    <rect id="Rectangle-152-Copy-5" fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"></rect>
                    <rect id="Rectangle-152-Copy-4" fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"></rect>
                </g>
              </SvgIcon>
            } />
          )
        }
      </BottomNavigation>
    </>
  )
}
