import React, {useState, useEffect, useRef} from "react";
import Media from '../components/Media/Media'
import {Radio, RadioGroup, FormControl, FormControlLabel, FormGroup, FormLabel, TextField, Grid, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Card, CardContent, CardActions, Typography, Button, TableCell, List, ListItem, ListItemAvatar, ListItemText, Avatar, Divider, ListItemIcon, ListItemSecondaryAction, Checkbox} from '@material-ui/core'
import { AvatarGroup, TabPanel } from '@material-ui/lab'
import ResourceList from '../components/ResourceList/ResourceList'
import ajax from '../utils/ajax'
import DateFnsUtils from '@date-io/date-fns';
import {AccomodationForm} from '../components/AccomodationForm/AccomodationForm'
import {Tabs, Tab, Modal} from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Meetings.scss'
import frLocale from "date-fns/locale/fr"
import {useGlobalData} from '../hooks/useGlobalData'

const saveBlob = (function () {
  var a = document.createElement("a")
  document.body.appendChild(a)
  a.style = "display: none"
  return function (blob, fileName) {
    var url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.type = "application/pdf"
    a.click()
    window.URL.revokeObjectURL(url)
  }
}())

const twoDigitString = (n) => {
  if (n < 10) return ('0' + n.toString())
  return n.toString()
}
const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
const shortDateString = (date, noDay) => {
  return (noDay ? '' : days[date.getDay()]) + ' ' + twoDigitString(date.getDate()) + '/' + twoDigitString(date.getMonth() + 1) + '/' + date.getFullYear()
}
const longDateString = (date) => {
  return days[date.getDay()] + ' ' + date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
}
const timeString = (date) => {
  return twoDigitString(date.getHours()) + ':' + twoDigitString(date.getMinutes())
}

export const Meetings = () => {
  const [commitees, setCommitees] = useState(null)
  const  [users, setUsers] = useState(null)
  const [commiteeArray, setCommiteeArray] = useState(null)
  const [user, setUser] = useState(null)
  const [documentsToAdd, setDocumentsToAdd] = useState([])
  const [missingUsers, setMissingUsers] = useState(null)
  const [presentUsers, setPresentUsers] = useState(null)
  const [unansweredUsers, setUnansweredUsers] = useState(null)
  const globalData = useGlobalData()

  const fetch = () => {
    ajax.get('/api/me').then(user => {
      setUser(user)
    })
    ajax.get('/api/commitees').then(r => {
      let commitees = {}
      for (let commitee of r) {
        commitees[commitee._id] = commitee
      }
      setCommitees(commitees)
      r.sort((a, b) => a.name.localeCompare(b.name))
      setCommiteeArray(r)
    })
    ajax.get('/api/users').then(r => {
      let users = {}
      for (let user of r) {
        users[user._id] = user
      }
      setUsers(users)
    })
  }
  useEffect(fetch, [])

  const pdfName = (meeting, type) => {
    let typeName = ''
    if (type === 'items') typeName = 'Ordre_du_jour'
    else if (type === 'registrations') typeName = 'Liste_des_inscrits'
    if (type === 'report') typeName = 'Compte_rendu'

    return meeting.commitee.name + '-' + shortDateString(new Date(meeting.date)) + '-' + typeName + '.pdf'
  }

  const pdfDocument = (meeting, type) => {
    let docs = [...documentsToAdd]
    let toAdd = docs.reverse().find(d => d.type === type)
    if (toAdd) return toAdd

    if (!meeting || !meeting.files) return undefined
    let files = [...meeting.files]
    return files.reverse().find(d => d.type === type)
  }

  const downloadDocument = (meeting, type) => {
    let file = pdfDocument(meeting, type)
    if (!file) return

    if (documentsToAdd.find(d => d.type === type)) {
      let reader = new FileReader()
      reader.readAsArrayBuffer(file.file);
      reader.onload = (e) => {
        const blob = new Blob([e.target.result], { type: 'application/pdf' })
        saveBlob(blob, pdfName(meeting, type))
      };
    } else {
      ajax.get('/api/document/' + file.media, {responseType: 'arraybuffer'}).then(res => {
        const blob = new Blob([res], { type: 'application/pdf' })
        saveBlob(blob, pdfName(meeting, type))
      })
    }
  }

  const downloadPDF = (meeting, type) => {
    if (meeting.hasPdfReport && type !== 'registrations') {
      downloadDocument(meeting, type)
      return
    }

    ajax.get('/api/meeting/' + meeting._id + '/pdf/' + type, {responseType: 'arraybuffer'}).then(res => {
      const blob = new Blob([res], { type: 'application/pdf' })
      saveBlob(blob, pdfName(meeting, type))
    })
  }

  const MeetingRow = (props) => {
    console.log(props)
    const [currentRegistration, setCurrentRegistration] = useState(null)

    if (!commiteeArray) return []

    const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    let date = new Date(props.date)

    const downloadProgram = downloadPDF.bind(null, props, 'items')
    const downloadReport = downloadPDF.bind(null, props, 'report')
    const downloadRegistrations = downloadPDF.bind(null, props, 'registrations')

    const registeredCount = (registered) => {
      if (!registered) return 0

      let count = 0
      for (let reg of registered) {
        for (let key in reg.options) {
          if (reg.options[key].selected) {
            ++count
            break
          }
        }
      }
      return count
    }

    const missingCount = (registered) => {
      if (!registered) return 0

      let count = 0
      for (let reg of registered) {
        let missing = true
        for (let key in reg.options) {
          if (reg.options[key].selected) {
            missing = false
            break
          }
        }
        if (missing) ++count
      }
      return count
    }

    const computePresentUsers = (registered) => {
      if (!registered) return null

      let list = []
      for (let reg of registered) {
        for (let key in reg.options) {
          if (reg.options[key].selected) {
            if (users[reg.user]) list.push(users[reg.user])
            break
          }
        }
      }
      list.sort((a, b) => a.lastName.localeCompare(b.lastName))
      return list
    }

    const computeMissingUsers = (registered) => {
      if (!registered) return null

      let list = []
      for (let reg of registered) {
        let missing = true
        for (let key in reg.options) {
          if (reg.options[key].selected) {
            missing = false
            break
          }
        }
        if (missing && users[reg.user]) list.push(users[reg.user])
      }
      list.sort((a, b) => a.lastName.localeCompare(b.lastName))
      return list
    }

    const computeUnansweredUsers = () => {
      if (!props.registered) return null

      let userObj = {}
      for (let u of commitees[props.commitee._id].users) {
        userObj[u] = true
      }
      for (let reg of props.registered) {
        delete userObj[reg.user]
      }


      let list = []
      for (let userId in userObj) {
        if (users[userId]) list.push(users[userId])
      }
      list.sort((a, b) => a.lastName.localeCompare(b.lastName))
      console.log(list)
      return list
    }

    const showRegistrationForm = () => {
      let registered = props.registered && props.registered.findIndex(reg => reg.user === user._id) >= 0
      if (!registered) {
        let reg = {options: {}}
        for (let dateStr in props.options) {
          if (props.options[dateStr].selected) {
            reg.options[dateStr] = {selected: false, matin: false, midi: false, soir: false, nuitee: false, typeChambre: 'single'}
          }
        }
        setCurrentRegistration(reg)
      } else {
        setCurrentRegistration(props.registered[props.registered.findIndex(reg => reg.user === user._id)])
      }
    }

    const canRegister = new Date(props.registrationDeadline).getTime() > new Date().getTime()
    const registration = props.registered.find(reg => (reg.user === user._id))
    const isRegistered = registration && registration.options && Object.values(registration.options).findIndex(opt => opt.selected) >= 0
    const isMissing = registration && registration.options && Object.values(registration.options).findIndex(opt => opt.selected) < 0

    const setMissing = () => {
      let reg = {...currentRegistration}
      let options = {...reg.options}
      for (let key in options) {
        options[key].selected = false
      }
      reg.options = options

      ajax.post('/api/meeting/' + props._id + '/register', reg).then(() => {
        setCurrentRegistration(null)
        props.fetch()
      })
    }

    const saveRegistration = () => {
      ajax.post('/api/meeting/' + props._id + '/register', currentRegistration).then(() => {
        setCurrentRegistration(null)
        props.fetch()
      })
    }

    let regCount = registeredCount(props.registered)
    let misCount = missingCount(props.registered)
    let unansweredCount = props.commitee ? commitees[props.commitee._id].users.length - regCount - misCount : 0

    return [
      <TableCell key='date'>{shortDateString(date) + ", " + timeString(date)}</TableCell>,
      <TableCell key='place'>{props.place || ''}</TableCell>,

      <TableCell key='commitee'>{props.commitee ? props.commitee.name : ''}</TableCell>,
      <TableCell key='registeredCount'>
        <div style={{display: "flex", flexDirection: 'column', alignItems: "flex-start"}}>
          {isRegistered && <div style={{marginBottom: '5px', color: "#17c191", textTransform: "uppercase"}}>
            <FontAwesomeIcon icon='check' style={{marginRight: '10px'}} />
            Inscrit
          </div>}
          {isMissing && <div style={{marginBottom: '5px', color: "red", textTransform: "uppercase"}}>
            <FontAwesomeIcon icon='times' style={{marginRight: '10px'}} />
            Absent
          </div>}
          <div style={{marginBottom: '5px', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => { setPresentUsers(computePresentUsers(props.registered)) }}>{regCount.toString() + (regCount > 1 ? ' participants' : ' participant')}</div>
          <div style={{marginBottom: '5px', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => { setMissingUsers(computeMissingUsers(props.registered)) }}>{misCount.toString() + (misCount > 1 ? ' absents' : ' absent')}</div>
          <div style={{marginBottom: '5px', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => { setUnansweredUsers(computeUnansweredUsers()) }}>{unansweredCount.toString() + (unansweredCount > 1 ? " n'ont pas répondu" : " n'a pas répondu")}</div>

          {
            canRegister
            ? (
              <Button variant="outlined" color='primary' size="small" onClick={showRegistrationForm}>
                <FontAwesomeIcon icon='user-edit' style={{marginRight: '15px'}} />
                {(isRegistered || isMissing) ? "Modifier mon inscription" : "Inscription"}
              </Button>
            )
            : <div style={{fontStyle: 'italic'}}>Inscriptions closes</div>
          }
        </div>
      </TableCell>,
      <TableCell key='documents'>
        {
          (!props.hasPdfReport && props.items !== "" || props.hasPdfReport && props.files.reverse().find(f => f.type === 'items')) && (
            <a href={(process.env.REACT_APP_BASE_URL || 'http://localhost:3001') + '/api/meeting/' + props._id + '/pdf/items'} target='_blank'>
              <Button variant="outlined" color='primary' size="small">
                <FontAwesomeIcon icon='download' style={{marginRight: '15px'}} />
                Ordre du jour
              </Button>
            </a>
          )
        }
        {
          new Date().getTime() > date.getTime() && (!props.hasPdfReport && props.reports !== "" || props.hasPdfReport && props.files.reverse().find(f => f.type === 'report')) && (
            <a href={(process.env.REACT_APP_BASE_URL || 'http://localhost:3001') + '/api/meeting/' + props._id + '/pdf/report'} target='_blank'>
              <Button variant="outlined" color='primary' size="small">
                <FontAwesomeIcon icon='download' style={{marginRight: '15px'}} />
                Compte-rendu
              </Button>
            </a>
          )
        }
        {
          props.registered && props.registered.length > 0 && (
            <a href={(process.env.REACT_APP_BASE_URL || 'http://localhost:3001') + '/api/meeting/' + props._id + '/pdf/registrations'} target='_blank'>
              <Button variant="outlined" color='primary' size="small">
                <FontAwesomeIcon icon='download' style={{marginRight: '15px'}} />
                Liste des inscrits
              </Button>
            </a>

          )
        }

        <Modal size="xl" scrollable show={currentRegistration !== null}>
          <Modal.Header>
            <Modal.Title>
              {(commitees[props.commitee] ? props.commitee.name : '') + ' - Réunion du ' + days[new Date(props.date).getDay()] + ' ' + new Date(props.date).toLocaleDateString() + ", " + new Date(props.date).toLocaleTimeString('fr-FR', {hour: 'numeric', minute: 'numeric'}) + (props.place ? (' - ' + props.place) : '')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              currentRegistration && (
                <Grid item container direction='column' spacing={1}>
                  <Grid item><Typography variant='h6'>Hébergement / Restauration proposés</Typography></Grid>
                  <Grid item><Typography>Veuillez cocher vos jours de présence ainsi que les prestations d'hébergement et de restauration souhaitées.</Typography></Grid>
                  <Grid item>
                    <AccomodationForm options={currentRegistration.options} availableOptions={props.options} setOptions={(options) => setCurrentRegistration(prevState => ({...prevState, options: options}))} />
                  </Grid>
                  <Grid item style={{marginTop: '30px'}}>
                    <div>Si vous n'êtes pas en mesure de participer à cette réunion, veuillez nous le signifier en cliquant sur le bouton ci-dessous :</div>
                    <Button variant="contained" color="error" onClick={() => { setMissing() }}>
                      Je serai absent
                    </Button>
                  </Grid>
                </Grid>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setCurrentRegistration(null) }}>Annuler</Button>
            <Button variant="contained" color="primary" onClick={saveRegistration}>
              {
                props.registered && props.registered.findIndex(reg => reg.user === user._id) >= 0
                ? "Modifier mon inscription"
                : "Valider mon inscription"
              }
            </Button>
          </Modal.Footer>
        </Modal>
      </TableCell>
    ]
  }

  /*const MeetingListItem = (props) => {
    let date = new Date(props.date)
    //let register = showMeeting.bind(null, props)
    let registered = props.registered && props.registered.findIndex(reg => reg.user === globalData.user._id) >= 0
    let canRegister = new Date(props.registrationDeadline).getTime() > new Date().getTime()
    const downloadProgram = downloadPDF.bind(null, props, 'items')
    const downloadReport = downloadPDF.bind(null, props, 'report')
    const downloadRegistrations = downloadPDF.bind(null, props, 'registrations')

    return (
      <ListItem>
        <ListItemText primary={commitees && commitees[props.commitee].name} secondary={
            <div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', lineHeight: 1, marginTop: '5px'}}>
                <FontAwesomeIcon icon='clock' style={{marginRight: '5px'}} />
                {days[date.getDay()] + ' ' + date.toLocaleDateString() + ", " + date.toLocaleTimeString('fr-FR', {hour: 'numeric', minute: 'numeric'})}
              </div>
              {
                props.place && (
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', lineHeight: 1, marginTop: '5px'}}>
                    <FontAwesomeIcon icon='map-marker' style={{marginRight: '8.5px'}} />
                    {props.place || ''}
                  </div>
                )
              }
            </div>
          } />
        <ListItemSecondaryAction>
          <Grid container direction='column' spacing={1} style={{alignItems: 'flex-end'}}>
            <Grid item>
              <Button variant="outlined" color='primary' size="small" onClick={downloadProgram}>
                <FontAwesomeIcon icon='download' style={{marginRight: '10px'}} />
                Ordre du jour
              </Button>
            </Grid>
            {
              canRegister && registered && (
                <Grid item>
                  <Button variant="outlined" size="small" onClick={register}>
                    <FontAwesomeIcon icon='user-check' style={{marginRight: '10px'}} />
                    Inscrit - Modifier
                  </Button>
                </Grid>
              )
            }
            {
              canRegister && !registered && (
                <Grid item>
                  <Button variant="outlined" color='primary' size="small" onClick={register}>
                    <FontAwesomeIcon icon='user-edit' style={{marginRight: '10px'}} />
                    Inscription
                  </Button>
                </Grid>
              )
            }
            {
              !canRegister && registered && (
                <Grid item>
                  <Button variant="outlined" size="small">
                    <FontAwesomeIcon icon='user-check' style={{marginRight: '10px'}} />
                    Inscrit
                  </Button>
                </Grid>
              )
            }
          </Grid>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }*/

  const MeetingForm = (props) => {
    const [activeItem, setActiveItem] = useState(false)
    const handleAccordionChange = (index, e, isExpanded) => { setActiveItem(isExpanded ? index : false) }

    const handleEditorChange = (field, content, editor) => {
      let res = {...props.editedResource}
      res[field] = content
      props.setEditedResource(res)
      props.setNeedsSaving(true)
     }

    const updateArrayElement = (field, i, e) => {
      const { name, value } = e.target;
      let array = [...props.editedResource[field]]
      array[i] = value
      props.setEditedResource(prevState => ({...prevState, [field]: array}))
      props.setNeedsSaving(true)
    }

    const addItem = () => {
      let items = [...props.editedResource.items, '']
      let reports = [...props.editedResource.reports, '']
      props.setEditedResource(prevState => ({...prevState, items: items, reports: reports}))
      props.setNeedsSaving(true)
    }

    const toggle = (e) => {
      let {name} = e.target
      props.setEditedResource(prevState => ({...prevState, [name]: !prevState[name]}))
      props.setNeedsSaving(true)
    }

    const date = new Date(props.editedResource.date)
    const endDate = new Date(props.editedResource.endDate)
    const registrationDeadline = new Date(props.editedResource.registrationDeadline)
    let yesterday = new Date(date)
    yesterday.setDate(date.getDate() - 1)
    let tomorrow = new Date(date)
    tomorrow.setDate(date.getDate() + 1)

    const setDates = (date, endDate) => {
      if (endDate.getTime() >= date.getTime()) {
        let options = {}
        let currentDate = new Date(date)
        let finishDate = new Date(endDate)
        currentDate.setDate(currentDate.getDate() - 1)
        finishDate.setDate(finishDate.getDate() + 1)
        finishDate.setHours(23)
        finishDate.setMinutes(59)
        finishDate.setSeconds(59)
        while (currentDate.getTime() <= finishDate.getTime()) {
          let dateStr = currentDate.getFullYear().toString() + twoDigitString(currentDate.getMonth() + 1) + twoDigitString(currentDate.getDate())
          if (!props.editedResource.options[dateStr]) {
            options[dateStr] = {matin: false, midi: false, soir: false, nuitee: false, selected: true}
          } else {
            options[dateStr] = {...props.editedResource.options[dateStr]}
          }
          currentDate.setDate(currentDate.getDate() + 1)
        }
        props.setEditedResource(prevState => ({...prevState, date: date, endDate: endDate, options: options}))
      } else {
        props.setEditedResource(prevState => ({...prevState, date: date, endDate: endDate}))
      }


      props.setReadyToSave(false)
      props.setNeedsSaving(true)
    }

    const renderEditor = (field) => {
      return (
        <Editor value={props.editedResource[field].replace(/src=\"api/g, "src=\"https://intranetv2.comptoirdelours.fr/api")} onEditorChange={handleEditorChange.bind(null, field)} apiKey='1a44ucvt07bi0x6k4rxvdbzc2im5lghbn2ojkkf0bp6cbquj'
        init={{
          width: '100%',
          height: '300px',
          language: 'fr_FR',
          language_url: '/langs/fr_FR.js',
          plugins: 'image preview autolink save link media table charmap hr anchor advlist wordcount help paste',
          menubar: 'file edit view insert format tools table help',
          toolbar: 'undo redo | bold italic underline strikethrough | formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | preview | insertfile image media template link anchor',
          content_css: [
              '//fonts.googleapis.com/css?family=Poppins|Open+Sans:400,700&display=swap',
            ],
          content_style: 'h1 {font-family: Poppins; font-size: 30px; color: rgba(0,0,0,0.87); font-weight: 700; text-transform: uppercase; text-align: center; margin-bottom: 20px!important;}' +
              'h2 {font-family: Poppins; font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(0,0,0,0.87);}' +
              'p {font-family: Open Sans; font-weight: 400; font-size: 14px; line-height: 25px; color: rgba(0,0,0,0.87); margin-bottom: 20px;}' +
              'body {max-width: 100%; padding: 10px 20px; font-family: Open Sans;}' +
              'img {max-width: 100%; height: auto; margin-bottom: 20px;}' +
              '.caption {color: red;}',
          block_formats: 'Titre principal=h1; Titre de section=h2; Paragraphe=p',
          style_formats: {title: 'Légende', format: 'caption'},
          formats: {
            caption: { block: 'div', classes: 'caption' }
          },
          paste_as_text: true,
          images_upload_handler: (blobInfo, success, failure) => {
            let data = new FormData()
            data.append('file', blobInfo.blob(), blobInfo.filename())
            ajax.put('/api/media', data).then(response => {
              console.log(response)
              success(response.path)
            }).catch(e => {
              failure()
            })
          }
        }}

        />
      )
    }

    const itemsInputRef = useRef()
    const reportInputRef = useRef()

    const setPdf = (type, e) => {
      let docs = [...documentsToAdd]
      let date = new Date()
      docs.push({file: e.target.files[0], type: type, date: date, id: date.getTime()})
      setDocumentsToAdd(docs)
      props.setNeedsSaving(true)
    }

    const saveBlob = (function () {
      var a = document.createElement("a")
      document.body.appendChild(a)
      a.style = "display: none"
      return function (blob, fileName) {
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }
    }())

    const renderGeneralTab = () => {
      return (
        <Grid container item direction='column' xs={12} md={12} spacing={2}>
          <Grid item>
            <Autocomplete style={{marginBottom: '20px'}} options={commiteeArray.map(u => u._id)} getOptionLabel={(opt) => (commitees[opt] ? commitees[opt].name : '')} value={props.editedResource.commitee || null} onChange={(e, c) => { props.setEditedResource(prevState => ({...prevState, commitee: c})); props.setReadyToSave(false); props.setNeedsSaving(true) }} renderInput={(params) => <TextField {...params} label="Commission" variant="outlined" />} />
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <Grid container item direction='row' spacing={2} wrap="nowrap">
            <Grid container item direction='column' spacing={2} style={{flexGrow: 0, flexShrink: 1, minWidth: 0}}>
              <Grid item><Typography variant='h6'>Début</Typography></Grid>
              <Grid item style={{marginTop: '-20px'}}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date de début"
                  value={props.editedResource.date}
                  onChange={(date) => { setDates(new Date(date), new Date(props.editedResource.endDate)) }}
                />
              </Grid>
              <Grid item>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Heure de début"
                  okLabel="OK"
                  cancelLabel="Annuler"
                  ampm={false}
                  value={props.editedResource.date}
                  onChange={(date) => { setDates(new Date(date), new Date(props.editedResource.endDate)) }}
                  inputProps={{
                    step: 300
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item direction='column' spacing={2} style={{flexGrow: 0, flexShrink: 1, minWidth: 0}}>
              <Grid item><Typography variant='h6'>Fin</Typography></Grid>
              <Grid item style={{marginTop: '-20px'}}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date de fin"
                  value={props.editedResource.endDate}
                  onChange={(date) => { setDates(props.editedResource.date, new Date(date)) }}
                />
              </Grid>
              <Grid item>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Heure de fin"
                  okLabel="OK"
                  cancelLabel="Annuler"
                  ampm={false}
                  value={props.editedResource.endDate}
                  onChange={(date) => { setDates(props.editedResource.date, new Date(date)) }}
                  inputProps={{
                    step: 300
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item direction='column' spacing={2} style={{flexGrow: 0, flexShrink: 1, minWidth: 0}}>
              <Grid item><Typography variant='h6'>Date limite d'inscription</Typography></Grid>
              <Grid item style={{marginTop: '-20px'}}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date de fin"
                  value={props.editedResource.registrationDeadline}
                  onChange={(date) => { props.setEditedResource(prevState => ({...prevState, registrationDeadline: new Date(date)})); props.setReadyToSave(false); props.setNeedsSaving(true) }}
                />
              </Grid>
              <Grid item>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Heure de fin"
                  okLabel="OK"
                  cancelLabel="Annuler"
                  ampm={false}
                  value={props.editedResource.registrationDeadline}
                  onChange={(date) => { props.setEditedResource(prevState => ({...prevState, registrationDeadline: new Date(date)})); props.setReadyToSave(false); props.setNeedsSaving(true) }}
                  inputProps={{
                    step: 300
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            {props.textInput('place', 'Lieu de la réunion', true)}
          </Grid>

          <Grid item>
            <Typography variant='h6'>Hébergement / Restauration proposés</Typography>
            <Grid item>
              <Grid item>
                <AccomodationForm options={props.editedResource.options} allEnabled setOptions={options => { props.setEditedResource(prevState => ({...prevState, options: options})); props.setReadyToSave(false); props.setNeedsSaving(true) }}  />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant='h6'>Ordre du jour / Compte-rendu</Typography>
            <FormGroup>
              <FormControlLabel control={<Checkbox name={'hasPdfReport'} checked={props.editedResource.hasPdfReport} onChange={toggle} />} label='Documents en PDF' />
            </FormGroup>
          </Grid>
          {
            !props.editedResource.hasPdfReport && (
              <>
                <Grid item>
                  <Typography variant='h6'>Ordre du jour</Typography>
                  {renderEditor('items')}
                </Grid>
                <Grid item>
                  <Typography variant='h6'>Compte-rendu</Typography>
                  {renderEditor('reports')}
                </Grid>
              </>
            )
          }
          {
            props.editedResource.hasPdfReport && (
              <Grid item container direction='column' spacing={2}>
                <Grid item container='row' spacing={2} alignItems='center'>
                  {pdfDocument(props.editedResource, 'items') && <Grid item><div style={{cursor: 'pointer', color: '#6993FF'}} onClick={downloadDocument.bind(null, props.editedResource, 'items')}>Ordre du jour</div></Grid>}
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={() => { itemsInputRef.current.click() }}>
                      <FontAwesomeIcon icon={pdfDocument(props.editedResource, 'items') ? 'edit' : 'plus'} style={{marginRight: '15px'}} />
                      {pdfDocument('items') ? "Modifier l'ordre du jour" : "Ajouter un ordre du jour"}
                    </Button>
                  </Grid>
                  <input type='file' accept="application/pdf" ref={itemsInputRef} style={{display: 'none'}} onChange={setPdf.bind(null, 'items')} />
                </Grid>
                <Grid item container='row' spacing={2} alignItems='center'>
                  {pdfDocument(props.editedResource, 'report') && <Grid item><div style={{cursor: 'pointer', color: '#6993FF'}} onClick={downloadDocument.bind(null, props.editedResource, 'report')}>Compte-rendu</div></Grid>}
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={() => { reportInputRef.current.click() }}>
                      <FontAwesomeIcon icon={pdfDocument(props.editedResource, 'report') ? 'edit' : 'plus'} style={{marginRight: '15px'}} />
                      {pdfDocument(props.editedResource, 'report') ? "Modifier le compte-rendu" : "Ajouter un compte-rendu"}
                    </Button>
                  </Grid>
                  <input type='file' accept="application/pdf" ref={reportInputRef} style={{display: 'none'}} onChange={setPdf.bind(null, 'report')} />
                </Grid>
              </Grid>
            )
          }
        </MuiPickersUtilsProvider>
        </Grid>
      )
    }

    const renderRegistrationsTab = () => {
      return (
        <Grid container item direction='column' xs={12} md={12} spacing={2}>
        </Grid>
      )
    }

    const save = () => {
      props.save(() => {
        setDocumentsToAdd([])
      })
    }

    const cancel = () => {
      props.cancel()
      setDocumentsToAdd([])
    }

    return (
      <Card className='Meetings' style={props.style}>
        <CardContent>
          {renderGeneralTab()}

          <CardActions style={{marginTop: '30px'}}>
            {
              props.needsSaving && (
                <Button variant="contained" color="primary" onClick={save}>
                  Enregistrer
                </Button>
              )
            }
            <Button variant="contained" color="secondary" onClick={cancel}>
              Annuler
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    )
  }

  const getNewMeeting = () => {
    let options = {}
    let currentDate = new Date()
    let finishDate = new Date()
    currentDate.setDate(currentDate.getDate() - 1)
    finishDate.setDate(finishDate.getDate() + 1)
    finishDate.setHours(23)
    finishDate.setMinutes(59)
    finishDate.setSeconds(59)
    while (currentDate.getTime() <= finishDate.getTime()) {
      let dateStr = currentDate.getFullYear().toString() + twoDigitString(currentDate.getMonth() + 1) + twoDigitString(currentDate.getDate())
      options[dateStr] = {matin: false, midi: false, soir: false, nuitee: false, selected: true}
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return {date: new Date(), endDate: new Date(), registrationDeadline: new Date(), options: options, items: "", reports: ""}
  }

  const removeQuestion = (c) => {
    return `Voulez-vous vraiment supprimer la réunion ${c.name} du ${new Date(c.date).toLocaleDateString()}, ${new Date(c.date).toLocaleTimeString()} ?`
  }

  const sortMeetings = (meetings) => {
    meetings.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
  }

  if (!user || !commitees) return null

  console.log(missingUsers)

  return (
    <>
      <ResourceList
        resourceType='meeting'
        normalUserApi='meetings/me'
        filterFields={['name', 'commitee.name', 'place']}
        addLabel="Organiser une réunion"
        removeLabel="Suppression d'une réunion"
        removeQuestion={removeQuestion}
        headers={['Date', 'Lieu', 'Commission', "Inscrits", 'Documents']}
        resourceRow={MeetingRow}
        resourceForm={MeetingForm}
        pictureFields={[]}
        getNewResource={getNewMeeting}
        sortResources={sortMeetings}
        dateIndex
        noNormalUserAction
        documentsToAdd={documentsToAdd}
      />

    <Modal scrollable show={missingUsers !== null}>
        <Modal.Header>
          <Modal.Title>
            Absents
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: 0}}>
          {
            missingUsers && (
              <List dense>
                {
                  missingUsers.map((user, i) => {
                    const fullName = user.firstName + ' ' + user.lastName.toUpperCase()
                    return (
                      <>
                        <ListItem key={i}>
                          <ListItemAvatar><Avatar alt={fullName} src={'/api/media/thumbnail/' + user.picture} /></ListItemAvatar>
                          <ListItemText primary={fullName} />
                        </ListItem>
                        <Divider />
                      </>
                    )
                  })
                }
              </List>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setMissingUsers(null) }}>Fermer</Button>
        </Modal.Footer>
      </Modal>

      <Modal scrollable show={presentUsers !== null}>
          <Modal.Header>
            <Modal.Title>
              Inscrits
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{padding: 0}}>
            {
              presentUsers && (
                <List dense>
                  {
                    presentUsers.map((user, i) => {
                      const fullName = user.firstName + ' ' + user.lastName.toUpperCase()
                      return (
                        <>
                          <ListItem key={i}>
                            <ListItemAvatar><Avatar alt={fullName} src={'/api/media/thumbnail/' + user.picture} /></ListItemAvatar>
                            <ListItemText primary={fullName} />
                          </ListItem>
                          <Divider />
                        </>
                      )
                    })
                  }
                </List>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setPresentUsers(null) }}>Fermer</Button>
          </Modal.Footer>
        </Modal>

        <Modal scrollable show={unansweredUsers !== null}>
            <Modal.Header>
              <Modal.Title>
                Sans réponse
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: 0}}>
              {
                unansweredUsers && (
                  <List dense>
                    {
                      unansweredUsers.map((user, i) => {
                        const fullName = user.firstName + ' ' + user.lastName.toUpperCase()
                        return (
                          <>
                            <ListItem key={i}>
                              <ListItemAvatar><Avatar alt={fullName} src={'/api/media/thumbnail/' + user.picture} /></ListItemAvatar>
                              <ListItemText primary={fullName} />
                            </ListItem>
                            <Divider />
                          </>
                        )
                      })
                    }
                  </List>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button style={{marginRight: '10px'}} variant="contained" color="secondary" onClick={() => { setUnansweredUsers(null) }}>Fermer</Button>
            </Modal.Footer>
          </Modal>
    </>
  )
}

export default Meetings
